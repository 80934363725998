
<template>
  <div class="">
    <div class="row gy-2">
      <div v-for="(item, itIndex) in items" :key="itIndex" class="col-md-12">
        <div v-if="item" class="card border-top overflow-hidden">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div v-if="item.lawyer"  class="d-flex align-items-center">
                <div  class="cursor-pointer me-3">
                  <img class="avatar rounded-circle" :src="absoluteUrl(item.lawyer.image)" />
                </div>
                <div class="cursor-pointer">
                  <div class="text-dark fw-bold font-sm text-capitalize">
                    <router-link :to="`/lawyers/${item.lawyer.id}`">
                      {{ item.lawyer.first_name}} {{ item.lawyer.last_name}}
                    </router-link>
                  </div>
                  <div><span class="text-dark font-sm">{{ item.lawyer.mobile }}</span></div>
                </div>
              </div>
            </div>
            <div class="pt-3">
              <div class="" >Status:  <span v-html="statusBadge(item.status)"></span></div>
              <div class="mt-2" v-if="item.duty">Assigned Duty: {{ item.duty.title }} </div>
              <div class="mt-2">Assigned At: {{$filters.date(item.assigned_at) }}</div>
              <div class="mt-2" v-if="item.deactivated_at">Deactivated At: {{$filters.date(item.deactivated_at) }} </div>
              <p v-if="item.creator" class="small mt-2 text-muted mb-0">
                Assigned by: {{item.creator.first_name}} {{item.creator.last_name}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  
export default {
  name: "case-lawyers",
  props:{
    items:{
      type: Array,
      default:() => ([])
    }
  },
}

</script>
  
  