<template>
  <div>
    <div class="card mb-4 overflow-hidden">
        <div class="card-body">
            <div class="row">
                <div class="col-10 col-sm-8">
                    <div class="d-flex align-items-center justify-content-start mb-3">
                        <div class="">
                            <div v-if="item.status" class=""><strong>Status:</strong> <span v-html="statusBadge(item.status.status, item.status.color)"></span></div>
                        </div>
                    </div>
                </div>
                <div class="col-2 col-sm-4 text-end">
                    <slot name="actions"></slot>
                </div>
                <div class="col-12">
                  <div class="row g-3">
                    <div class="col-12"><h5 class="mb-1">{{item.parties}}</h5></div>
                    <div class="col-md-6"><strong>{{item.number_type}} Number:</strong> <span>{{item.case_number}}</span></div>
                    <div class="col-md-6"><strong>Category:</strong> <span v-if="item.category">{{item.category.title}}</span></div>
                    <div v-if="item.adjourned_date" class="col-md-12"><strong>Next Adjourned Date:</strong> <span>{{$filters.date(item.adjourned_date, 2) }}</span></div>
                    <div v-if="item.legal_instrument" class="col-md-6"><strong>Legal instrument:</strong> <span>{{item.legal_instrument }}</span></div>
                    <div v-if="item.duration_of_contract" class="col-md-6"><strong>Duration of Contract:</strong> <span>{{item.duration_of_contract }}</span></div>
                    <div class="col-12">
                        <label class="form-label fw-bold">Summary of Facts</label>
                        <div v-html="preText(item.description)"></div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-4 overflow-hidden">
      <div class="card-body">
        <h6 class="mb-4"> {{ item.number_type == 'Charge' ? 'Case Offence' : item.number_type == 'Suit' ? 'Nature of Suit' : 'Offence or Nature of suit'}} </h6>
        <ol class="list-unstyled">
          <li v-for="(case_charge, chrIndex) in item.charges" :key="chrIndex">
            <hr class="my-2">
            <div v-if="case_charge.creator" class="mb-3 d-flex align-items-center">
              <div  class="cursor-pointer me-3">
                <img style="width: 1.5rem; height: 1.5rem;" class="avatar-sm rounded-circle" :src="absoluteUrl(case_charge.creator.image)" />
              </div>
              <div class="cursor-pointer">
                <div class="text-dark fw-bold font-sm text-capitalize">
                  <router-link :to="`/lawyers/${case_charge.creator.id}`">
                    {{ case_charge.creator.first_name}} {{ case_charge.creator.last_name}}
                  </router-link>
                </div>
              </div>
            </div>
            <h6 class="mb-1">{{case_charge.title}}</h6>
            <p class="mb-0"><small>{{case_charge.description}}</small></p>
          </li>
        </ol>
      </div>
    </div>
    <div class="card mb-4 overflow-hidden">
      <div class="card-body">
        <h6 class="mb-4">Case Opinions</h6>
        <ol class="list-unstyled">
          <li v-for="(case_opinion, copIndex) in item.charges" :key="copIndex">
            <hr class="my-2">
            <div v-if="case_opinion.creator" class="mb-3 d-flex align-items-center">
              <div  class="cursor-pointer me-3">
                <img style="width: 1.5rem; height: 1.5rem;" class="avatar-sm rounded-circle"
                 :src="absoluteUrl(case_opinion.creator.image)" />
              </div>
              <div class="cursor-pointer">
                <div class="text-dark fw-bold font-sm text-capitalize">
                  <router-link :to="`/lawyers/${case_opinion.creator.id}`">
                    {{ case_opinion.creator.first_name}} {{ case_opinion.creator.last_name}}
                  </router-link>
                </div>
              </div>
            </div>
            <h6 class="mb-1">{{case_opinion.title}}</h6>
            <p class="mb-0"><small>{{case_opinion.description}}</small></p>
          </li>
        </ol>
      </div>
    </div>
    <div class="row">
        <div v-if="item.current_stage" class="col-lg-6">
            <div class="card shadow-sm">
              <div class="card-body">
                <h6>Stage</h6>
                <p class="mb-0 text-primary"> {{item.current_stage.title}}</p>
                <p class="mt-1 mb-0"><small>{{item.current_stage.description}}</small></p>
              </div>
            </div>
        </div>
        <div v-if="item.current_court" class="col-lg-6">
            <div class="card shadow-sm">
              <div class="card-body">
                <h6>Court</h6>
                <p class="mb-0 text-primary"> {{item.current_court.name}}</p>
                <p class="mt-1 mb-0"><small>{{item.current_court.location}}</small></p>
              </div>
            </div>
        </div>
        <div v-if="item.presiding_judge" class="col-lg-6">
            <div class="card shadow-sm">
              <div class="card-body">
                <h6>Presiding Judge</h6>
                <p class="mb-0 text-primary"> {{item.presiding_judge.name}}</p>
                <p class="mt-1 mb-0 "><small> Gender: {{$filters.capitalize(item.presiding_judge.gender)}}</small></p>
              </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        item: {
            type: Object,
            required: true
        }
    }
}
</script>

<style>

</style>