<template>
    <div class="card">
        <div class="card-body">
            <div v-if="item.creator" class="mb-2">
                <div class="d-flex">
                    <img :src="absoluteUrl(item.creator.image)" class="rounded-circle me-3 avatar" />
                    <div>
                        <h3 class="card-title mb-1">
                            <span>
                                {{item.creator.first_name}} {{item.creator.last_name}}
                            </span>
                        </h3>
                        <p class="mb-1 small text-muted">{{item.creator.mobile}} </p>
                    </div>
                </div>
                <hr class="my-1">
            </div>
            <div class="pt-3">
                <h6 class=""> {{ item.title }} </h6>
                <div class="mb-3" v-html="preText(item.description)"> </div>
                <div class="">{{ $filters.date(item.created_at) }}</div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    export default {
        props:{
            item:{
                type: Object,
                required: true
            }
        },
    }
    </script>
    
    <style>
    
    </style>