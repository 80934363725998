
<template>
  <div>
    <div v-if="!isLoading" class="row gy-2">
      <div v-if="item" class="col-12">
        <h5 class="mb-3">Case Details</h5>
        <div class="row g-4">
          <div class="col-md-8">
            <b-tabs active-tab-class="active" 
              nav-class="bg-white shadow nav-tabs-custom nav-primary mb-3">
              <b-tab :active="activeTab === 1">
                <template v-slot:title>
                  <span class="nav-label">Overview</span>
                </template>
                <case-overview :item="item">
                  <template v-slot:actions>
                    <div class="dropdown dropstart no-arrow">
                      <a class="dropdown-toggle btn btn-sm btn-light" href="#"
                        role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="ri-more-2-fill"></i>
                      </a>
                      <ul class="dropdown-menu">
                        <!-- <li>
                          <a @click="popupAddLawyer = true" class="dropdown-item"
                          href="javascript:void(0)" >
                            Assign Lawyer </a>
                        </li> -->
                      </ul>
                    </div>
                  </template>
                </case-overview>
              </b-tab>
              <b-tab :active="activeTab === 2" :lazy="false">
                <template v-slot:title>
                  <span class="nav-label">Case Histories</span>
                </template>
                <case-histories :caseId="item.id" />
              </b-tab>
              <b-tab :active="activeTab === 3" :lazy="false">
                <template v-slot:title>
                  <span class="nav-label">Law Officers</span>
                </template>
                <case-lawyers :items="item.assignments" />
              </b-tab>
            </b-tabs>
          </div>
          <div class="col-md-4">
            <div class="position-sticky" style="top: 90px;">
              <div v-if="item.department" class="card shadow-sm">
                <div class="card-body">
                  <h6>Department</h6>
                  <p class="mb-0"><router-link to="/department">
                    {{item.department.title}}</router-link></p>
                  <p v-if="item.department.description" class="mt-1 mb-0"><small>{{item.department.description}}</small></p>
                </div>
              </div>
              <div v-if="item.creator" class="card border-top overflow-hidden">
                <div class="bg-light py-4">
                </div>
                <div class="card-body text-center">
                  <a href="javascript:void(0)" class="avatar rounded-circle avatar-lg card-avatar card-avatar-top">
                    <img :src="absoluteUrl(item.creator.image)" class="avatar-img rounded-circle border border-4 border-card" alt="...">
                  </a>
                  <small>
                    Created by
                  </small>
                  <h3 class="card-title">
                    <a href="javascript:void(0)">{{item.creator.first_name}} {{item.creator.last_name}}</a>
                  </h3>
                  <p class="small text-muted mb-0">
                    {{item.creator.email}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <is-loading v-else />
  </div>
  </template>
  
<script>
import IsLoading from '@/components/IsLoading.vue'
import CaseHistories from './CaseHistories.vue'
import CaseLawyers from './CaseLawyers.vue'
import CaseOverview from './CaseOverview.vue'
  
export default {
  name: "item-show",
  components:{
    IsLoading,
    CaseHistories,
    CaseLawyers,
    CaseOverview
  },
  data() {
    return {
      isLoading: true,
      popupModalShow: false,
      item: null
    }
  },
  watch: {
    '$route.params.caseId': function (id){
      if(id) this.fetchItem()
    },
  },
  computed:{
    activeTab(){
      return this.$route.query.tab ? +this.$route.query.tab : 1;
    },
  },
  methods: {
    updateItem(item){
      Object.assign(this.item, item)
    },
    fetchItem(){
      this.isLoading = true
      this.$http.get(`/cases/${this.$route.params.caseId}/show`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.item = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    initializeAll(){
      this.popupModalShow = false;
    }
  },
  created(){
    this.fetchItem()
  },
}
  
</script>
  
  